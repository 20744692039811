import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ClearIcon from '@mui/icons-material/Clear'
import DescriptionIcon from '@mui/icons-material/Description'
import SearchIcon from '@mui/icons-material/Search'
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { DIALOGS_CLIENT } from 'const'
import dayjs from 'dayjs'
import { getConceptoListSelector, getEmpresaListSelector, getUsuarioSelector } from 'features'
import { saveAs } from 'file-saver'
import { AuditDialog, ClientesTable, Dashboard, EditClienteDialog, SendEmailDialog } from 'pages'
import React from 'react'
import { getClienteListService, getClienteReporteService } from 'services'
import { ClienteResponse, IConcepto, IEmpresa, PaginationResponse } from 'types'

export const Clientes = () => {
    const [busqueda, setBusqueda] = React.useState<string>()
    const [empresa, setEmpresa] = React.useState<number | undefined>(undefined)
    const [clientes, setClientes] = React.useState<PaginationResponse>()
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [openEdit, setOpenEdit] = React.useState<boolean>(false)
    const [openAudit, setOpenAudit] = React.useState<boolean>(false)
    const [openSendEmail, setOpenSendEmail] = React.useState<boolean>(false)
    const [selectedCliente, setSelectedCliente] = React.useState<ClienteResponse>()
    const [filteredEmpresa, setFilteredEmpresa] = React.useState<IEmpresa[]>([])
    const [filteredConcepto, setFilteredConcepto] = React.useState<IConcepto[]>([])
    const [concepto, setConcepto] = React.useState<number[]>([])
    const empresaList = useAppSelector(getEmpresaListSelector)
    const conceptosList = useAppSelector(getConceptoListSelector)
    const usuario = useAppSelector(getUsuarioSelector)

    const loadValidEmpresas = () => {
        const validEmpresas: IEmpresa[] = []

        if (usuario !== undefined) {
            if (usuario?.permisoSuperdeporte) {
                validEmpresas.push(empresaList[0])
            }

            if (usuario?.permisoMedeportAdidas) {
                validEmpresas.push(empresaList[1])
            }

            if (usuario?.permisoMedeportReebok) {
                validEmpresas.push(empresaList[2])
            }

            if (usuario?.permisoEquinox) {
                validEmpresas.push(empresaList[3])
            }
        }
        if (validEmpresas.length > 0) {
            setEmpresa(validEmpresas[0].id)
            getConceptosByEmpresa(validEmpresas[0].id)
        }

        setFilteredEmpresa(validEmpresas)
    }

    const getConceptosByEmpresa = (empresaId: number) => {
        const conceptos = conceptosList.filter((concepto) => concepto.empresaId === empresaId)
        setFilteredConcepto(conceptos)
    }

    const toStringConcepto = (concepto: number[]): string | undefined => {
        if (concepto.length > 0) {
            return concepto.join(',')
        } else return undefined
    }

    const allConceptos = () => {
        return concepto.length === filteredConcepto.length
    }

    const handleGetClientes = async (rowsPerPage: number, page: number, empresa?: number, concepto?: string, busqueda?: string) => {
        setClientes(await getClienteListService(rowsPerPage, page, empresa, allConceptos() ? undefined : concepto, busqueda !== '' ? busqueda : undefined))
    }

    const handleSetPage = (_page: number) => {
        setPage(_page)
    }

    const handleSetRowsPerPage = (_rowsPerPage: number) => {
        setRowsPerPage(_rowsPerPage)
    }

    const handleOpenDialog = (cliente: ClienteResponse, dialog: string) => {
        if (dialog === DIALOGS_CLIENT.EDIT_CLIENTE) {
            setOpenEdit(true)
        } else if (dialog === DIALOGS_CLIENT.AUDIT_CLIENTE) {
            setOpenAudit(true)
        } else if (dialog === DIALOGS_CLIENT.SEND_EMAIL) {
            setOpenSendEmail(true)
        }
        setSelectedCliente(cliente)
    }

    const handleCloseDialog = (dialog: string) => {
        if (dialog === DIALOGS_CLIENT.EDIT_CLIENTE) {
            setOpenEdit(false)
            handleGetClientes(rowsPerPage, page, empresa, toStringConcepto(concepto), busqueda)
        } else if (dialog === DIALOGS_CLIENT.AUDIT_CLIENTE) {
            setOpenAudit(false)
        } else if (dialog === DIALOGS_CLIENT.SEND_EMAIL) {
            setOpenSendEmail(false)
        }
        setSelectedCliente(undefined)
    }

    const handleSetSelectedCliente = (cliente: ClienteResponse) => {
        setSelectedCliente(cliente)
    }

    const handleSelectAll = () => {
        if (allConceptos()) {
            setConcepto([])
        } else {
            setConcepto(filteredConcepto.map((concepto) => concepto.conceptoId))
        }
    }

    const handleGetReporteClientes = async (empresa?: number, concepto?: string, busqueda?: string) => {
        const bytes = await getClienteReporteService(empresa, allConceptos() ? undefined : concepto, busqueda)
        const timestamp = dayjs(new Date()).format('YYYYMMDDhhmmss')
        saveAs(bytes, 'reporte' + timestamp + '.csv')
    }

    React.useEffect(() => {
        loadValidEmpresas()
    }, [empresaList, conceptosList])

    React.useEffect(() => {
        if (empresa !== undefined && empresa === 1) {
            getConceptosByEmpresa(empresa)
        }
    }, [empresa])

    return (
        <Dashboard title="Clientes">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    justifyContent: { xs: 'center', lg: 'space-between' },
                    alignItems: 'center',
                    width: '100%',
                    gap: 1.2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 1.3,
                        flexGrow: 2
                    }}
                >
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Buscar por nombre, apellido, num. documento, email..."
                        sx={{ width: '270px' }}
                        value={busqueda}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setBusqueda(event.target.value)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            setBusqueda('')
                                        }}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControl size="small">
                        <InputLabel style={{ color: '#C4C4C4' }}>Empresa</InputLabel>
                        <Select
                            value={empresa !== undefined ? empresa.toString() : ''}
                            onChange={(event: SelectChangeEvent) => {
                                setEmpresa(Number.parseInt(event.target.value))
                                
                            }}
                            sx={{ width: '270px' }}
                            label="Empresa"
                            style={{ borderRadius: '0px' }}
                        >
                            {filteredEmpresa.map((empresa) => (
                                <MenuItem key={empresa.id} value={empresa.id}>
                                    {empresa.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {empresa === 1 && (
                        <FormControl size="small">
                            {/*  //? Se condicionó el Label para que solo se muestre cuando haya un elemento seleccionado  */}
                            {concepto.length > 0 && <InputLabel shrink={true}>Conceptos</InputLabel>}
                            <Select
                                multiple
                                sx={{ width: '270px' }}
                                value={concepto.length > 0 ? concepto : []}
                                onChange={(event: SelectChangeEvent<typeof concepto>) => {
                                    const selectedValues = event.target.value as number[]
                                    if (selectedValues.includes(0)) {
                                        handleSelectAll()
                                    } else {
                                        setConcepto(selectedValues)
                                    }
                                }}
                                label={concepto.length > 0 ? 'Conceptos' : undefined}
                                style={{ borderRadius: '0px' }}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return 'Seleccione un concepto '
                                    }

                                    return (selected as number[])
                                        .map((optionId) => {
                                            const option = filteredConcepto.find((concepto) => concepto.conceptoId === optionId)
                                            return option ? option.nombre : ''
                                        })
                                        .join(', ')
                                }}
                            >
                                <MenuItem value={0} onClick={() => handleSelectAll()}>
                                    <ListItemIcon>{allConceptos() ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</ListItemIcon>
                                    <ListItemText primary="Todos" />
                                </MenuItem>
                                {filteredConcepto.map((con) => (
                                    <MenuItem
                                        key={con.conceptoId}
                                        value={con.conceptoId}
                                        style={{
                                            backgroundColor: concepto.includes(con.conceptoId) ? 'transparent' : 'initial',
                                        }}
                                    >
                                        <ListItemIcon>{concepto.includes(con.conceptoId) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</ListItemIcon>
                                        <ListItemText primary={con.nombre} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>

                <Box
                    sx={{ display: 'flex', flexGrow: 1, width: '100%', justifyContent: { xs: 'center', md: 'flex-end' }, alignItems: 'center', gap: 1}}
                >
                    <Button
                        variant="outlined"
                        startIcon={<ClearIcon sx={{ ml: 1 }} />}
                        sx={{ height: '40px', fontSize: '13px',width:'115px' }}
                        onClick={() => {
                            setBusqueda('')
                            handleGetClientes(rowsPerPage, 0, empresa)
                            setConcepto([])
                        }}
                    >
                        Limpiar
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<SearchIcon sx={{ ml: 1 }} />}
                        sx={{ height: '40px', fontSize: '13px',width:'115px'  }}
                        onClick={() => {
                            handleSetPage(0)
                            handleGetClientes(rowsPerPage, 0, empresa, empresa === 1 ? toStringConcepto(concepto) : undefined, busqueda)
                        }}
                    >
                        Buscar
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<DescriptionIcon sx={{ ml: 1 }} />}
                        sx={{ height: '40px', fontSize: '12px' }}
                        onClick={() => handleGetReporteClientes(empresa, toStringConcepto(concepto), busqueda)}
                    >
                        Generar CSV
                    </Button>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                <ClientesTable
                    concepto={toStringConcepto(concepto)}
                    busqueda={busqueda}
                    empresa={empresa}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleSetPage={handleSetPage}
                    handleSetRowsPerPage={handleSetRowsPerPage}
                    clientes={clientes}
                    handleGetClientes={handleGetClientes}
                    handleOpenDialog={handleOpenDialog}
                />
            </Box>
            <EditClienteDialog open={openEdit} cliente={selectedCliente} handleClose={handleCloseDialog} handleSetSelectedCliente={handleSetSelectedCliente} />
            <AuditDialog open={openAudit} cliente={selectedCliente} handleClose={handleCloseDialog} />
            <SendEmailDialog open={openSendEmail} cliente={selectedCliente} handleClose={handleCloseDialog} />
        </Dashboard>
    )
}
