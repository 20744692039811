import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ICatalogoState, IConcepto, IEmpresa, IGenero, ITipoCliente, ITipoDocumento } from 'types'

const initialState = {
    tipoDocumentoList: [] as ITipoDocumento[],
    generoList: [] as IGenero[],
    empresaList: [] as IEmpresa[],
    conceptoList: [] as IConcepto[],
    tipoClienteList: [] as ITipoCliente[],
} as ICatalogoState

const CatalogoSlice = createSlice({
    name: 'catalogo',
    initialState,
    reducers: {
        setTipoDocumentoList: (state, action: PayloadAction<ITipoDocumento[]>) => {
            const tipoDocumentoList = action.payload
            return { ...state, tipoDocumentoList }
        },
        setGeneroList: (state, action: PayloadAction<IGenero[]>) => {
            const generoList = action.payload
            return { ...state, generoList }
        },
        setEmpresaList: (state, action: PayloadAction<IEmpresa[]>) => {
            const empresaList = action.payload
            return { ...state, empresaList }
        },
        setConceptoList: (state, action: PayloadAction<IConcepto[]>) => {
            const conceptoList = action.payload
            return { ...state, conceptoList }
        },
        setTipoClienteList: (state, action: PayloadAction<ITipoCliente[]>) => {
            const tipoClienteList = action.payload
            return { ...state, tipoClienteList }
        },
    },
})

export const catalogoReducer = CatalogoSlice.reducer

export const { setTipoDocumentoList, setGeneroList, setEmpresaList, setConceptoList, setTipoClienteList } = CatalogoSlice.actions

export const getTipoDocumentoListSelector = (state: RootState) => state.catalogo.tipoDocumentoList

export const getGeneroListSelector = (state: RootState) => state.catalogo.generoList

export const getEmpresaListSelector = (state: RootState) => state.catalogo.empresaList

export const getConceptoListSelector = (state: RootState) => state.catalogo.conceptoList

export const getTipoClienteListSelector = (state: RootState) => state.catalogo.tipoClienteList
