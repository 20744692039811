import { PrivateLayout } from 'layouts'
import { Clientes, Usuarios } from 'pages'
import { RouteObject } from 'react-router-dom'
import { ROUTES } from 'routes'

export const PrivateRoute: RouteObject = {
    path: ROUTES.private,
    element: <PrivateLayout />,
    children: [
        {
            path: ROUTES.clientes,
            element: <Clientes />,
        },
        {
            path: ROUTES.usuarios,
            element: <Usuarios />,
        },
    ],
}
