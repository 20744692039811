import LoginIcon from '@mui/icons-material/Login'
import { LoadingButton } from '@mui/lab'
import { Alert, AlertColor, AlertTitle, Box, Icon, TextField } from '@mui/material'
import { useAuth } from 'hooks'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'routes'
import { IAlert, UsuarioLoginRequest } from 'types'
import { fieldValidationEmail } from 'utils'

export const LoginForm = () => {
    const [correo, setCorreo] = React.useState<string>()
    const [password, setPassword] = React.useState<string>()
    const [showPassword, setShowPassword] = React.useState(false)
    const [alert, setAlert] = React.useState<IAlert>()
    const navigate = useNavigate()
    const { handleLogin, loading, isAuthenticated } = useAuth()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const actualAlerts: IAlert[] = []
        if (correo === undefined || correo.trim().length <= 0 || password === undefined || password.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar correo electrónico y contraseña.' })
        }
        if (correo !== undefined && correo.trim().length > 0) {
            if (!fieldValidationEmail(correo)) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un email válido.' })
            }
        }
        if (actualAlerts.length > 0) {
            setAlert(actualAlerts[0])
        } else {
            // setLoading(true)
            setAlert(undefined)
            if (correo && password) {
                const usuarioLoginRequest: UsuarioLoginRequest = {
                    correo,
                    clave: password,
                }
                try {
                    await handleLogin(usuarioLoginRequest)
                } catch (error: any) {
                    setAlert({ severity: 'error', title: 'Error', message: error.message })
                }
            }
        }
    }
    React.useEffect(() => {
        if (isAuthenticated()) {
            navigate(ROUTES.root + ROUTES.private + ROUTES.root + ROUTES.clientes)
        }
    }, [isAuthenticated])

    return (
        <form onSubmit={handleSubmit}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '90vh',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mx: 4,
                    }}
                >
                    <LoginIcon sx={{ fontSize: '5rem !important' }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '80%',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="correo"
                        name="correo"
                        label="Correo Electrónico"
                        variant="outlined"
                        value={correo}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCorreo(event.target.value)
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '80%',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="paswword"
                        name="paswword"
                        label="Contraseña"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value)
                        }}
                        InputProps={{
                            endAdornment: (
                                <Icon onClick={() => setShowPassword(!showPassword)} sx={{ cursor: 'pointer', ml: 1 }}>
                                    {showPassword ? 'visibility_off' : 'visibility'}
                                </Icon>
                            ),
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '80%',
                        my: 2,
                        mx: 4,
                    }}
                >
                    {alert && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                m: '4px',
                            }}
                        >
                            <Alert severity={alert.severity as AlertColor}>
                                <AlertTitle>{alert.title}</AlertTitle>
                                {alert.message}
                            </Alert>
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '60%',
                        mx: 4,
                        mb: 2,
                    }}
                >
                    <LoadingButton variant="outlined" loading={loading} type="submit">
                        {' INICIAR SESIÓN'}
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    )
}
