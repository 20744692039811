import {
    Alert,
    AlertColor,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import { DIALOGS_USER } from 'const'
import React, { Fragment } from 'react'
import { createUsuarioService } from 'services'
import { IAlert, NewUsuarioRequest, UsuarioResponse } from 'types'
import { fieldValidationEmail, fieldValidationIdentification } from 'utils'

interface Props {
    open: boolean
    handleClose: (dialog: string) => void
}

export const NewUsuarioDialog = (props: Props) => {
    const { open, handleClose } = props
    const [data, setData] = React.useState<UsuarioResponse>({} as UsuarioResponse)
    const [alert, setAlert] = React.useState<IAlert>()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const actualAlerts: IAlert[] = []

        if (data.nombre === undefined || data.nombre.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar el nombre.' })
        }

        if (data.identificacion === undefined || data.identificacion.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar la identificación' })
        }

        if (data.identificacion !== undefined && data.identificacion.trim().length > 0) {
            if (!fieldValidationIdentification(data.identificacion)) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'La identificacion debe ser válida y tener 10 dígitos.' })
            }
        }

        if (data.correo === undefined || data.correo.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un email.' })
        }

        if (data.correo !== undefined && data.correo.trim().length > 0) {
            if (!fieldValidationEmail(data.correo)) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un email válido.' })
            }
        }

        let hasOne = false
        if (data.permisoUsuarios !== undefined && data.permisoUsuarios) {
            hasOne = true
        } else {
            if (
                (data.permisoSuperdeporte !== undefined && data.permisoSuperdeporte) ||
                (data.permisoEquinox !== undefined && data.permisoEquinox) ||
                (data.permisoMedeportAdidas !== undefined && data.permisoMedeportAdidas) ||
                (data.permisoMedeportReebok !== undefined && data.permisoMedeportReebok)
            ) {
                hasOne = true
            }
        }

        if (!hasOne) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe seleccionar al menos un permiso (usuarios o empresa).' })
        }

        if (actualAlerts.length > 0) {
            setAlert(actualAlerts[0])
        } else {
            setAlert(undefined)
            const usuarioRequest: NewUsuarioRequest = {
                nombre: data.nombre,
                identificacion: data.identificacion,
                correo: data.correo,
                permisoUsuarios: data.permisoUsuarios ?? false,
                permisoSuperdeporte: data.permisoSuperdeporte ?? false,
                permisoMedeportAdidas: data.permisoMedeportAdidas ?? false,
                permisoMedeportReebok: data.permisoMedeportReebok ?? false,
                permisoEquinox: data.permisoEquinox ?? false,
            }
            try {
                const response = await createUsuarioService(usuarioRequest)
                console.log(response.usuarioId)
                setAlert({ severity: 'success', title: '', message: 'El registro del nuevo usuario ha sido creado.' })
                handleClose(DIALOGS_USER.NEW_USUARIO)
            } catch (error) {
                console.log(error)
                setAlert({ severity: 'error', title: 'Error', message: 'error' })
            }
        }
    }

    const handleCloseSnackbar = () => {
        setAlert(undefined)
    }

    const changeValueEntity = (key: keyof UsuarioResponse, value: UsuarioResponse[keyof UsuarioResponse]) => {
        setData((prev) => ({ ...prev, [key]: value }))
    }

    const getValueData = (key: keyof UsuarioResponse): UsuarioResponse[keyof UsuarioResponse] => {
        const value = data[key]
        return value ?? ''
    }

    React.useEffect(() => {}, [])

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={() => {
                    handleClose(DIALOGS_USER.NEW_USUARIO)
                }}
                fullWidth
                maxWidth="md"
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Información del Usuario</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Ingrese la información para el nuevo usuario.</DialogContentText>
                        <Grid container gap={2} sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Grid item xs={3.6}>
                                <TextField
                                    id="nombre"
                                    name="nombre"
                                    label="Nombre"
                                    variant="outlined"
                                    size="small"
                                    value={getValueData('nombre')}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        changeValueEntity(event.target.name as keyof UsuarioResponse, event.target.value.toUpperCase())
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3.6}>
                                <TextField
                                    id="identificacion"
                                    name="identificacion"
                                    label="Identificación"
                                    variant="outlined"
                                    size="small"
                                    value={getValueData('identificacion')}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        changeValueEntity(event.target.name as keyof UsuarioResponse, event.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3.6}>
                                <TextField
                                    id="correo"
                                    name="correo"
                                    label="Correo"
                                    variant="outlined"
                                    size="small"
                                    value={getValueData('correo')}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        changeValueEntity(event.target.name as keyof UsuarioResponse, event.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!getValueData('permisoUsuarios')}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    changeValueEntity('permisoUsuarios', event.target.checked)
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>Permiso para Usuarios</Typography>
                                        }
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!getValueData('permisoSuperdeporte')}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    changeValueEntity('permisoSuperdeporte', event.target.checked)
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                Permiso para Superdeporte
                                            </Typography>
                                        }
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!getValueData('permisoMedeportAdidas')}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    changeValueEntity('permisoMedeportAdidas', event.target.checked)
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                Permiso para Medeport Adidas
                                            </Typography>
                                        }
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!getValueData('permisoMedeportReebok')}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    changeValueEntity('permisoMedeportReebok', event.target.checked)
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                Permiso para Medeport Rebook
                                            </Typography>
                                        }
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!getValueData('permisoEquinox')}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    changeValueEntity('permisoEquinox', event.target.checked)
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>Permiso para Equinox</Typography>
                                        }
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" size="small" sx={{ height: '40px', fontSize: '14px' }}>
                            CREAR
                        </Button>
                        <Button
                            size="small"
                            onClick={() => {
                                handleClose(DIALOGS_USER.NEW_USUARIO)
                            }}
                            sx={{ height: '40px', fontSize: '14px' }}
                        >
                            CANCELAR
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Snackbar open={alert !== undefined} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={alert?.severity as AlertColor} onClose={handleCloseSnackbar}>
                    {alert?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}
