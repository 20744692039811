import { Box, CssBaseline } from '@mui/material'
import { AppProvider } from 'app/context'
import { Outlet } from 'react-router-dom'

// this is a template for the routes of the app
export const GeneralLayout = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
                sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '0px',
                    maxWidth: '500px',
                    margin: '0 auto',
                    width: '100%',
                    height: 'auto',
                    minHeight: '600px',
                    mt: 2,
                    border: 'solid 1px white'
                }}
            >
                <CssBaseline />

                <AppProvider>
                    <Outlet />
                </AppProvider>
            </Box>
        </Box>
    )
}
