import { LoadingButton } from '@mui/lab'
import { Box, Card, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'routes'
import RefreshIcon from '@mui/icons-material/Refresh'

export type NotFoundPageProps = {}

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                height: '95vh',
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                gap: 4,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:2
                }}
            >
                <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 700, color: '#142939', pb: 6 }}>
                    ERROR 404
                </Typography>
                <Card sx={{background:'transparent', boxShadow:'0'}}>
                    <CardMedia component="img" alt="NotFound image" image="/assets/notFound.svg"  />
                </Card>

                <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 250, color: '#142939' }}>
                    Lo sentimos, pero la página que busca no existe o no se puede encontrar.
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '60%',
                    mx: 4,
                    mb: 2,
                }}
            >
                <LoadingButton sx={{ borderRadius: 3, fontSize: 18 }} type="button" onClick={() => navigate(ROUTES.root + ROUTES.login, { replace: true })}>
                    {' Regresar al Inicio'}
                    <RefreshIcon sx={{ ml: 1 }} />
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default NotFoundPage
