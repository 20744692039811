import axios from 'axios'
import { PATHS } from 'const'
import { UsuarioLoginRequest, UsuarioResponse } from 'types'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SECURITY_API_KEY}`,
    },
})

export const loginUsuarioService = async (usuarioLogin: UsuarioLoginRequest) => {
    try {
        const response = (await instance.post(`${PATHS.USUARIO_PATH}/login`, usuarioLogin)).data
        return response as UsuarioResponse
    } catch (error: any) {
        throw new Error(error.response?.data?.message ?? error.message)
    }
}
