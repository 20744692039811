import { Alert, AlertColor, Button, Dialog, DialogActions, Icon, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar, TextField } from '@mui/material'
import React, { Fragment } from 'react'
import { updateUsuarioPassswordService } from 'services'
import { IAlert, UsuarioChangePasswordRequest, UsuarioResponse } from 'types'
import { fieldValidatinPassword } from 'utils'
import bcrypt from 'bcryptjs-react'
import { DIALOGS_USER } from 'const'

interface Props {
    open: boolean
    usuario: UsuarioResponse | undefined
    handleClose: (dialog: string) => void
    handleSetSelectedUsuario: (usuario: UsuarioResponse) => void
}

export const EditUsuarioPasswordDialog = (props: Props) => {
    const { open, usuario, handleClose, handleSetSelectedUsuario } = props
    const [password, setPassword] = React.useState<string>('')
    const [showPassword, setShowPassword] = React.useState(false)
    const [alert, setAlert] = React.useState<IAlert>()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const actualAlerts: IAlert[] = []

        if (password === undefined || password.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar una contraseña en el campo.' })
        }

        if (password !== undefined && password.trim().length > 0) {
            if (!fieldValidatinPassword(password)) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'La contraseña debe tener al menos 8 dígitos.' })
            }
        }

        if (actualAlerts.length > 0) {
            setAlert(actualAlerts[0])
        } else {
            setAlert(undefined)

            if (usuario) {
                const usuarioRequest: UsuarioChangePasswordRequest = {
                    usuarioId: usuario.usuarioId,
                    clave: bcrypt.hashSync(password, 10),
                }
                const response = await updateUsuarioPassswordService(usuarioRequest)
                handleSetSelectedUsuario(response)
            }
            setAlert({ severity: 'success', title: '', message: 'El registro del Usuario ha sido modificado.' })
            handleClose(DIALOGS_USER.EDIT_PASSWORD)
            setPassword('')
        }
    }

    const handleCloseSnackbar = () => {
        setAlert(undefined)
    }

    return (
        <Fragment>
            {usuario && (
                <Dialog
                    open={open}
                    onClose={() => {
                        handleClose(DIALOGS_USER.EDIT_PASSWORD)
                    }}
                    fullWidth
                    maxWidth="xs"
                >
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>Edición Contraseña del Usuario</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Toda edición de información registra la fecha y hora de actualización.</DialogContentText>
                            <Grid container gap={2} sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item xs={10}>
                                    <TextField
                                        id="contraseña"
                                        name="contraseña"
                                        label="Contraseña"
                                        variant="outlined"
                                        autoComplete="off"
                                        size="small"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setPassword(event.target.value)
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <Icon onClick={() => setShowPassword(!showPassword)} sx={{ cursor: 'pointer', ml: 1 }}>
                                                    {showPassword ? 'visibility_off' : 'visibility'}
                                                </Icon>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" size="small" sx={{ height: '40px', fontSize: '14px' }}>
                                MODIFICAR
                            </Button>
                            <Button
                                size="small"
                                onClick={() => {
                                    handleClose(DIALOGS_USER.EDIT_PASSWORD)
                                    setPassword('')
                                    setShowPassword(false)
                                }}
                                sx={{ height: '40px', fontSize: '14px' }}
                            >
                                CANCELAR
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
            <Snackbar open={alert !== undefined} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={alert?.severity as AlertColor} onClose={handleCloseSnackbar}>
                    {alert?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}
