import axios, { AxiosError } from 'axios'
import { PATHS } from 'const'
import { ClienteIntegrationResponse } from 'types'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SECURITY_API_KEY}`,
    },
})

export const getInfoClienteFromIntegrationService = async (identificacionEmpresa: string, identificacionCliente: string) => {
    try {
        const response = await instance.get(PATHS.MARATHON_PATH + PATHS.CLIENTE_PATH, { params: { identificacionEmpresa, identificacionCliente } })
        return response.data as ClienteIntegrationResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}
