import { Box } from '@mui/material'
import { FormContent } from './FormContent'
import { FormHeader } from './FormHeader'

interface Props {
    empresaId: number
}

export const DataForm = (props: Props) => {
    const { empresaId } = props
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                m: 1,
                mb: 4,
            }}
        >
            <FormHeader empresaId={empresaId} />
            <FormContent empresaId={empresaId} />
        </Box>
    )
}
