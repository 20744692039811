import { Box, Typography } from '@mui/material'

type Props = {
    title: string
    children?: React.ReactNode
}

export const Dashboard: React.FC<Props> = (props: Props) => {
    const { title, children } = props
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems:{xs:'center',md:'flex-start'}, mx: 3, mt: 2, width: '100%' }}>
            <Typography variant="h3">{title}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', mt: 2, width: '100%'}}>{children}</Box>
        </Box>
    )
}
