import AddIcon from '@mui/icons-material/Add'
import { Box, Button, TextField } from '@mui/material'
import { DIALOGS_USER } from 'const'
import { Dashboard, EditUsuarioDialog, EditUsuarioPasswordDialog, EditUsuarioStateDialog, NewUsuarioDialog, UsuariosTable } from 'pages'
import React from 'react'
import { getUserListService } from 'services'
import { UsuarioResponse } from 'types'

export const Usuarios = () => {
    const [usuarios, setUsuarios] = React.useState<UsuarioResponse[]>([])
    const [busqueda, setBusqueda] = React.useState<string>()
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [openNew, setOpenNew] = React.useState<boolean>(false)
    const [openEdit, setOpenEdit] = React.useState<boolean>(false)
    const [openPassword, setOpenPassword] = React.useState<boolean>(false)
    const [openState, setOpenState] = React.useState<boolean>(false)
    const [selectedUsuario, setSelectedUsuario] = React.useState<UsuarioResponse>()

    const handleGetUsuarios = async () => {
        setUsuarios(await getUserListService())
    }

    const handleSetPage = (_page: number) => {
        setPage(_page)
    }

    const handleSetRowsPerPage = (_rowsPerPage: number) => {
        setRowsPerPage(_rowsPerPage)
    }

    const handleOpenDialog = (usuario: UsuarioResponse | undefined, dialog: string) => {
        if (dialog === DIALOGS_USER.NEW_USUARIO) {
            setOpenNew(true)
        } else if (dialog === DIALOGS_USER.EDIT_USUARIO) {
            setOpenEdit(true)
        } else if (dialog === DIALOGS_USER.EDIT_PASSWORD) {
            setOpenPassword(true)
        } else if (dialog === DIALOGS_USER.EDIT_ESTADO) {
            setOpenState(true)
        }

        setSelectedUsuario(usuario)
    }

    const handleCloseEditDialog = (dialog: string) => {
        if (dialog === DIALOGS_USER.NEW_USUARIO) {
            setOpenNew(false)
        } else if (dialog === DIALOGS_USER.EDIT_USUARIO) {
            setOpenEdit(false)
        } else if (dialog === DIALOGS_USER.EDIT_PASSWORD) {
            setOpenPassword(false)
        } else if (dialog === DIALOGS_USER.EDIT_ESTADO) {
            setOpenState(false)
        }
        setSelectedUsuario(undefined)
        handleGetUsuarios()
    }

    const handleSetSelectedUsuario = (usuario: UsuarioResponse) => {
        setSelectedUsuario(usuario)
    }

    React.useEffect(() => {
        handleGetUsuarios()
    }, [])

    return (
        <Dashboard title="Usuarios">
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    justifyContent: { xs: 'center', md: 'space-between' },
                }}
            >
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Buscar por nombre, identificación, email..."
                    sx={{ width: {xs:'360px',md:'400px',lg:'500px'} }}
                    value={busqueda}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setBusqueda(event.target.value)
                    }}
                />
                <Button
                    variant="outlined"
                    startIcon={<AddIcon sx={{ ml: 1 }} />}
                    sx={{ ml: 2, pr: 2, height: '40px', fontSize: '14px', width: {xs:'300px',md:'200px'} }}
                    onClick={() => {
                        handleOpenDialog(undefined, DIALOGS_USER.NEW_USUARIO)
                    }}
                >
                    Nuevo Usuario
                </Button>
            </Box>
            <Box sx={{ display: 'flex', mt: 2 }}>
                <UsuariosTable
                    busqueda={busqueda}
                    usuarios={usuarios}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleSetPage={handleSetPage}
                    handleSetRowsPerPage={handleSetRowsPerPage}
                    handleOpenDialog={handleOpenDialog}
                />
            </Box>
            <NewUsuarioDialog open={openNew} handleClose={handleCloseEditDialog} />
            <EditUsuarioDialog
                open={openEdit}
                usuario={selectedUsuario}
                handleClose={handleCloseEditDialog}
                handleSetSelectedUsuario={handleSetSelectedUsuario}
            />
            <EditUsuarioStateDialog
                open={openState}
                usuario={selectedUsuario}
                handleClose={handleCloseEditDialog}
                handleSetSelectedUsuario={handleSetSelectedUsuario}
            />
            <EditUsuarioPasswordDialog
                open={openPassword}
                usuario={selectedUsuario}
                handleClose={handleCloseEditDialog}
                handleSetSelectedUsuario={handleSetSelectedUsuario}
            />
        </Dashboard>
    )
}
