import { GeneralLayout } from 'layouts'
import { LoginForm } from 'pages'
import { DataForm } from 'pages/MainForm'
import { NotFoundPage } from 'pages/NotFoundPage'
import { RouteObject } from 'react-router-dom'
import { ROUTES } from 'routes'

export const PublicRoute: RouteObject = {
    path: ROUTES.none,
    element: <GeneralLayout />,
    children: [
        {
            path: ROUTES.nike,
            element: <DataForm empresaId={4} />,
        },
        {
            path: ROUTES.adidas,
            element: <DataForm empresaId={2} />,
        },
        {
            path: ROUTES.reebok,
            element: <DataForm empresaId={3} />,
        },
        {
            path: ROUTES.superdeporte,
            element: <DataForm empresaId={1} />,
        },
        {
            path: ROUTES.login,
            element: <LoginForm />,
        },
        {
            path: ROUTES.other,
            element: <NotFoundPage />,
        },
    ],
}
