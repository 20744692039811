import axios, { AxiosError } from 'axios'
import { LOCALSTORAGE_KEYS, PATHS } from 'const'
import { EditUsuarioRequest, NewUsuarioRequest, UsuarioChangePasswordRequest, UsuarioResponse } from 'types'

type IHeaders = {
    Authorization: string
    Token: string
}

const getItem = (key: string): any | undefined => {
    const value = localStorage.getItem(key)
    try {
        if (value) {
            const json = JSON.parse(value)
            return json
        }
    } catch (error) {
        return undefined
    }
}

const getHeaders = (): IHeaders => {
    const token = getItem(LOCALSTORAGE_KEYS.MARATHON) ?? ''
    return {
        Authorization: `Bearer ${process.env.REACT_APP_SECURITY_API_KEY}`,
        Token: token,
    }
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
})

const setAuthHeaders = (Authorization: string, Token: string) => {
    instance.defaults.headers.common['Authorization'] = Authorization
    instance.defaults.headers.common['Token'] = Token
}

export const getUserListService = async () => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = await instance.get(PATHS.USUARIO_PATH)
        return response.data as UsuarioResponse[]
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const createUsuarioService = async (usuario: NewUsuarioRequest) => {
    try {
        const response = (await instance.post(PATHS.USUARIO_PATH, usuario)).data
        return response as UsuarioResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const updateUsuarioService = async (usuario: EditUsuarioRequest) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = (await instance.put(PATHS.USUARIO_PATH, usuario)).data
        return response as UsuarioResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const updateUsuarioActivoService = async (id: number) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = (await instance.delete(`${PATHS.USUARIO_PATH}/${id}`)).data
        return response as UsuarioResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const updateUsuarioPassswordService = async (usuario: UsuarioChangePasswordRequest) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = (await instance.put(`${PATHS.USUARIO_PATH}/password`, usuario)).data
        return response as UsuarioResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}
export const logoutUsuario = async (id: number) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = (await instance.put(`${PATHS.USUARIO_PATH}/logout/${id}`)).data
        return response as UsuarioResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const getUsuarioByTokenService = async (token: string) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = (await instance.get(`${PATHS.USUARIO_PATH}/info`, { params: { token } })).data
        return response as UsuarioResponse
    } catch (error: any) {
        throw new Error(error.response?.data?.message ?? error.message)
    }
}
