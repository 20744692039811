import React, { Fragment } from 'react'
import { EMPRESAS } from './Data/termsandprivacy'
import { Box, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ROUTES } from 'routes'
import { Helmet } from 'react-helmet'
import { MARCAS, PAGES } from 'const'
import { AcordionAllTerms, AcordionListTerms } from 'components'

type TermsAndPrivacyProps = {
    marca: string
    tipo: string
}

export const TermsAndPrivacy: React.FC<TermsAndPrivacyProps> = (props: TermsAndPrivacyProps) => {
    const { marca, tipo } = props

    const obtenerInformacion = (nombreMarca: string, tipo: string) => {
        const empresa = EMPRESAS.find((e) => e.marca === nombreMarca)
        if (empresa) {
            if (tipo === ROUTES.terminos) {
                return empresa.terminos
            } else if (tipo === ROUTES.privacidad) {
                return empresa.privacidad
            }
        }
        return undefined
    }
    const informacion = obtenerInformacion(marca, tipo)

    const getMarcaComercial = (): string => {
        if (marca === ROUTES.superdeporte) {
            return MARCAS.SUPERDEPORTE_MARCA
        } else if (marca === ROUTES.equinox) {
            return MARCAS.EQUINOX_MARCA
        } else if (marca === ROUTES.reebok) {
            return MARCAS.MEDEPORT_REEBOK_MARCA
        } else if (marca === ROUTES.adidas) {
            return MARCAS.MEDEPORT_ADIDAS_MARCA
        }
        return 'No se encontró información de la marca comercial'
    }

    const getTitulo = (): string => {
        if (tipo === ROUTES.terminos) {
            return `Términos y Condiciones - ${getMarcaComercial()} `
        } else if (tipo === ROUTES.privacidad) {
            return `Política de Privacidad - ${getMarcaComercial()}`
        }
        return 'No se encontró información de la marca'
    }

    const navigateToPage = (): void => {
        if (marca === ROUTES.superdeporte) {
            window.location.assign(PAGES.SUPERDEPORTE_PAGE)
        } else if (marca === ROUTES.equinox) {
            window.location.assign(PAGES.EQUINOX_PAGE)
        } else if (marca === ROUTES.reebok) {
            window.location.assign(PAGES.MEDEPORT_REEBOOK_PAGE)
        } else if (marca === ROUTES.adidas) {
            window.location.assign(PAGES.MEDEPORT_ADIDAS_PAGE)
        }
    }

    return (
        <Fragment>
            <Box>
                <Helmet>
                    <title>{getTitulo()}</title>
                </Helmet>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    mx: 2,
                    mb: 4,
                }}
            >
                <Box style={{ maxWidth: '40px', margin: '0 auto' }}>
                    <IconButton onClick={() => navigateToPage()}>
                        <ArrowBackIcon />
                    </IconButton>
                </Box>
                <Typography variant="h5" sx={{ fontWeight: '700', fontSize: '25px', textAlign: 'center', paddingBottom: '10px' }}>
                    {getTitulo()}
                </Typography>
                {informacion ? (
                    <Fragment>

                        {tipo === ROUTES.privacidad ? (
                            <AcordionListTerms listInfo={informacion} />
                        ) : (
                            <Fragment>
                                <AcordionAllTerms listInfo={informacion} company={marca} />
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <p style={{ textAlign: 'center' }}>
                        No se encontró información de {tipo} para {marca}
                    </p>
                )}
            </Box>
        </Fragment>
    )
}
