export const COLORS = {
    primary: {
        main: '#9D9D9D',
    },
    secondary: {
        main: '#C4C4C4',
    },
    background: {
        main: '#272727',
    },
    buttonText: {
        main: '#FFFFFF',
    },
}
