import {
    Alert,
    AlertColor,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useAppSelector } from 'app/hooks'
import { DIALOGS_CLIENT } from 'const'
import dayjs from 'dayjs'
import { getConceptoListSelector, getGeneroListSelector, getTipoClienteListSelector, getTipoDocumentoListSelector, getUsuarioSelector } from 'features'
import React, { Fragment } from 'react'
import { updateClienteService } from 'services'
import { ClienteRequest, ClienteResponse, IAlert } from 'types'

interface Props {
    open: boolean
    cliente: ClienteResponse | undefined
    handleClose: (dialog: string) => void
    handleSetSelectedCliente: (cliente: ClienteResponse) => void
}

const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const EditClienteDialog = (props: Props) => {
    const { open, cliente, handleClose, handleSetSelectedCliente } = props
    const [data, setData] = React.useState<ClienteResponse>({} as ClienteResponse)
    const [alert, setAlert] = React.useState<IAlert>()
    const tipoDocumentoList = useAppSelector(getTipoDocumentoListSelector)
    const tipoClienteList = useAppSelector(getTipoClienteListSelector)
    const generoList = useAppSelector(getGeneroListSelector)
    const conceptoList = useAppSelector(getConceptoListSelector)
    const usuario = useAppSelector(getUsuarioSelector)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const actualAlerts: IAlert[] = []
        let esMayor = true

        if (data.nombres === undefined || data.nombres.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar los nombres.' })
        }

        if (data.apellidos === undefined || data.apellidos.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar los apellidos.' })
        }

        if (data.email === undefined || data.email.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un email.' })
        }

        if (data.email !== undefined && data.email.trim().length > 0) {
            if (!expression.test(data.email)) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un email válido.' })
            }
        }

        if (data.fechaNacimiento === undefined) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar una fecha de nacimiento.' })
        }

        if (data.fechaNacimiento !== undefined && data.fechaNacimiento.trim().length > 0) {
            if (data.fechaNacimiento.toString() === 'Invalid Date') {
            } else {
                try {
                    const nacimientoDayjs = dayjs(data.fechaNacimiento)
                    const age = dayjs().diff(nacimientoDayjs, 'year')
                    if (age < 18) {
                        esMayor = false
                        actualAlerts.push({ severity: 'error', title: 'Error', message: `El cliente es menor de edad` })
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }

        if (data.celular === undefined || data.celular.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un número celular.' })
        }

        if (data.celular !== undefined && data.celular.trim().length > 0) {
            if (data.celular.match(/^[0-9]+$/) === null || data.celular.trim().length !== 10) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'Ingrese un número de celular válido.' })
            }
        }

        if (cliente?.empresa === 1) {
            const conceptos = data.conceptos.split(',').length === 1 && data.conceptos.split(',')[0] === '' ? [] : data.conceptos.split(',')
            if (conceptos.length === 0) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe seleccionar al menos un canal comercial.' })
            }
        }

        if (data.ultimoMotivo === undefined || data.ultimoMotivo === null || data.ultimoMotivo.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un motivo para la modificación.' })
        }

        if (actualAlerts.length > 0) {
            setAlert(actualAlerts[0])
        } else {
            setAlert(undefined)

            const clienteRequest: ClienteRequest = {
                clienteId: data.clienteId,
                nombres: data.nombres,
                apellidos: data.apellidos,
                genero: data.genero,
                email: data.email,
                fechaNacimiento: dayjs(data.fechaNacimiento).format('YYYY/MM/DD'),
                empresa: data.empresa,
                celular: data.celular,
                conceptos: data.conceptos,
                avisoPrivacidad: data.avisoPrivacidad,
                terminos: data.terminos,
                esMayorEdad: esMayor,
                activo: data.activo,
                ultimoMotivo: data.ultimoMotivo,
                tipoCliente: data.tipoCliente ?? null,
                usuarioId: usuario!.usuarioId,
            }

            const response = await updateClienteService(clienteRequest)
            handleSetSelectedCliente(response)

            setAlert({ severity: 'success', title: '', message: 'El registro del cliente ha sido modificado.' })
            handleClose(DIALOGS_CLIENT.EDIT_CLIENTE)
        }
    }

    const handleCloseSnackbar = () => {
        setAlert(undefined)
    }

    const changeValueEntity = (key: keyof ClienteResponse, value: ClienteResponse[keyof ClienteResponse]) => {
        setData((prev) => ({ ...prev, [key]: value }))
    }

    const getValueData = (key: keyof ClienteResponse): ClienteResponse[keyof ClienteResponse] => {
        const value = data[key]
        return value ?? ''
    }

    const handleConceptoChange = (index: number, checked: boolean) => {
        const actualConceptos = data.conceptos.split(',').length === 1 && data.conceptos.split(',')[0] === '' ? [] : data.conceptos.split(',')
        if (checked) {
            if (!actualConceptos.includes(index.toString())) {
                actualConceptos.push(index.toString())
            }
        } else {
            if (actualConceptos.includes(index.toString())) {
                const elementIndex = actualConceptos.findIndex((i) => i === index.toString())
                actualConceptos.splice(elementIndex, 1)
            }
        }
        changeValueEntity('conceptos', actualConceptos.join(','))
    }

    const isConceptoChecked = (index: number) => {
        const conceptosArray = data.conceptos?.split(',')
        return conceptosArray?.find((i) => i === index.toString()) !== undefined ? true : false
    }

    React.useEffect(() => {
        if (cliente !== undefined) setData(cliente)
    }, [cliente])

    return (
        <Fragment>
            {cliente && (
                <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>Editar Información Cliente</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Toda edición de información será guardada como registro de auditoria.</DialogContentText>
                            <Grid container gap={2} sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="nombres"
                                        name="nombres"
                                        label="Nombres"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('nombres')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity(event.target.name as keyof ClienteResponse, event.target.value.toUpperCase())
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="apellidos"
                                        name="apellidos"
                                        label="Apellidos"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('apellidos')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity(event.target.name as keyof ClienteResponse, event.target.value.toUpperCase())
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="tipoDocumento"
                                        name="tipoDocumento"
                                        label="Tipo Documento"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={tipoDocumentoList.find((t) => t.codigo === getValueData('tipoDocumento'))?.descripcion}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="numDocumento"
                                        name="numDocumento"
                                        label="Número de documento"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={getValueData('numDocumento')}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <FormControl sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                        <RadioGroup
                                            row
                                            sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}
                                            value={getValueData('genero').toString()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                changeValueEntity('genero', event.target.value)
                                            }}
                                        >
                                            {generoList.map((genero) => (
                                                <FormControlLabel
                                                    key={genero.codigo}
                                                    value={genero.codigo}
                                                    control={<Radio />}
                                                    label={<Typography sx={{ fontWeight: '300' }}>{genero.descripcion}</Typography>}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('email')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity(event.target.name as keyof ClienteResponse, event.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <DatePicker
                                        label="Fecha de nacimiento"
                                        format="YYYY-MM-DD"
                                        slotProps={{ textField: { size: 'small' } }}
                                        value={getValueData('fechaNacimiento') !== '' ? dayjs(getValueData('fechaNacimiento').toString()) : null}
                                        onChange={(newValue) => {
                                            if (newValue !== null) {
                                                changeValueEntity('fechaNacimiento', newValue.format('YYYY-MM-DD'))
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="celular"
                                        name="celular"
                                        label="Celular"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('celular')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity(event.target.name as keyof ClienteResponse, event.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('esMayorEdad')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('esMayorEdad', event.target.checked)
                                                    }}
                                                    disabled
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>Es mayor de edad?</Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('avisoPrivacidad')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('avisoPrivacidad', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                    Aviso de Privacidad
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('terminos')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('terminos', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                    Términos y Condiciones
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('activo')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('activo', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={<Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>Activo</Typography>}
                                        />
                                    </FormGroup>
                                </Grid>
                                {cliente?.empresa === 1 && (
                                    <Grid item xs={12} sx={{ px: '27px' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Grid container columns={11} spacing={0}>
                                                {conceptoList
                                                    .filter((concepto) => concepto.empresaId === cliente.empresa)
                                                    .map((concepto, index) => (
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            key={index}
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    mx: 0,
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    checked={isConceptoChecked(index + 1)}
                                                                    value={isConceptoChecked(index + 1)}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        handleConceptoChange(index + 1, event.target.checked)
                                                                    }}
                                                                />
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        height: '30px',
                                                                        mx: 0,
                                                                    }}
                                                                >
                                                                    <img
                                                                        key={concepto.logo}
                                                                        title={concepto.nombre}
                                                                        src={`/assets/sd/${concepto.logo}.png`}
                                                                        alt={concepto.logo}
                                                                        width="60"
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    ))}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="insertado"
                                        name="insertado"
                                        label="Insertado"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={getValueData('insertado')}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="actualizado"
                                        name="actualizado"
                                        label="Actualizado"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={getValueData('actualizado')}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel style={{ color: '#C4C4C4' }}>Tipo de cliente</InputLabel>
                                        <Select
                                            id="tipoCliente"
                                            name="tipoCliente"
                                            value={getValueData('tipoCliente').toString()}
                                            onChange={(event: SelectChangeEvent) => {
                                                changeValueEntity('tipoCliente', event.target.value)
                                            }}
                                            label="Tipo de cliente"
                                            style={{ borderRadius: '0px' }}
                                        >
                                            {tipoClienteList.map((tipoCliente) => (
                                                <MenuItem key={tipoCliente.tipoClienteId} value={tipoCliente.tipoClienteId}>
                                                    {tipoCliente.descripcion}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="ultimoMotivo"
                                        name="ultimoMotivo"
                                        label="Motivo de Modificación"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('ultimoMotivo')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity(event.target.name as keyof ClienteResponse, event.target.value)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" size="small" sx={{ height: '40px', fontSize: '14px' }}>
                                MODIFICAR
                            </Button>
                            <Button size="small" onClick={() => handleClose(DIALOGS_CLIENT.EDIT_CLIENTE)} sx={{ height: '40px', fontSize: '14px' }}>
                                CANCELAR
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
            <Snackbar open={alert !== undefined} onClose={handleCloseSnackbar}>
                <Alert severity={alert?.severity as AlertColor} onClose={handleCloseSnackbar}>
                    {alert?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}
