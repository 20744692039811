import EditIcon from '@mui/icons-material/Edit'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import PersonOffIcon from '@mui/icons-material/PersonOff'

import VpnKeyIcon from '@mui/icons-material/VpnKey'
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    useTheme,
} from '@mui/material'
import { DIALOGS_USER } from 'const'
import React, { Fragment } from 'react'
import { UsuarioResponse } from 'types'

interface TablePaginationActionsProps {
    count: number
    page: number
    rowsPerPage: number
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props
    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0)
    }

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1)
    }

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    )
}

interface Props {
    busqueda: string | undefined
    usuarios: UsuarioResponse[] | undefined
    page: number
    rowsPerPage: number
    handleSetPage: (page: number) => void
    handleSetRowsPerPage: (rowsPerPage: number) => void
    handleOpenDialog: (usuario: UsuarioResponse, dialog: string) => void
}

export const UsuariosTable = (props: Props) => {
    const { usuarios, page, rowsPerPage, handleOpenDialog, handleSetPage, handleSetRowsPerPage, busqueda } = props
    const [usersPaginate, SetUsersPaginate] = React.useState<UsuarioResponse[]>([])

    const getUsersPaginate = (_page: number, _rowsPerPage: number, _busqueda?: string): void => {
        let paginatedUsers: UsuarioResponse[] = []
        const startIndex = _page * _rowsPerPage
        const endIndex = startIndex + _rowsPerPage

        // Aplicar el filtro por los campos de búsqueda
        if (busqueda) {
            const filteredUsers = usuarios?.filter((usuario) => {
                const { nombre, identificacion, correo } = usuario
                const normalizedSearchQuery = busqueda.toLowerCase()
                return (
                    nombre.toLowerCase().includes(normalizedSearchQuery) ||
                    identificacion.toLowerCase().includes(normalizedSearchQuery) ||
                    correo.toLowerCase().includes(normalizedSearchQuery)
                )
            })
            paginatedUsers = filteredUsers?.slice(startIndex, endIndex > filteredUsers.length - 1 ? undefined : endIndex) ?? []
            SetUsersPaginate(paginatedUsers)
        } else {
            paginatedUsers = usuarios?.slice(startIndex, endIndex > usuarios.length - 1 ? undefined : endIndex) ?? []
            SetUsersPaginate(paginatedUsers)
        }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        handleSetPage(newPage)
        getUsersPaginate(newPage, rowsPerPage, busqueda)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleSetRowsPerPage(parseInt(event.target.value, 10))
        handleSetPage(0)
        getUsersPaginate(0, parseInt(event.target.value, 10), busqueda)
    }

    React.useEffect(() => {
        getUsersPaginate(page, rowsPerPage, busqueda)
    }, [usuarios, busqueda])

    return (
        <TableContainer component={Paper}>
            <Table size="small" sx={{ width: '100%' }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ with: '170px' }} align="center">
                            Nombres
                        </TableCell>
                        <TableCell align="center">Identificación</TableCell>
                        <TableCell align="center">Correo</TableCell>
                        <TableCell align="center">Activo</TableCell>
                        <TableCell align="center">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                {usuarios && (
                    <Fragment>
                        <TableBody>
                            {usersPaginate.map((usuario) => (
                                <TableRow key={usuario.usuarioId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center" component="th" scope="row">
                                        {usuario.nombre}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {usuario.identificacion}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {usuario.correo}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {usuario.activo ? 'Activo' : 'Inactivo'}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <Tooltip title="Editar">
                                            <IconButton onClick={() => handleOpenDialog(usuario, DIALOGS_USER.EDIT_USUARIO)} size="small">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Activar o Desactivar Usuario">
                                            <IconButton onClick={() => handleOpenDialog(usuario, DIALOGS_USER.EDIT_ESTADO)} size="small">
                                                <PersonOffIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Cambiar Contraseña">
                                            <IconButton onClick={() => handleOpenDialog(usuario, DIALOGS_USER.EDIT_PASSWORD)} size="small">
                                                <VpnKeyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    colSpan={11}
                                    count={busqueda ? usersPaginate.length : usuarios.length}
                                    labelRowsPerPage="registros por página"
                                    labelDisplayedRows={(page) => `${page.from} - ${page.to === -1 ? page.count : page.to} de ${page.count}`}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Fragment>
                )}
            </Table>
        </TableContainer>
    )
}
