import { GeneralLayout } from 'layouts'
import { TermsAndPrivacy } from 'pages'
import { RouteObject } from 'react-router-dom'
import { ROUTES } from 'routes'

export const DocsRoute: RouteObject = {
    path: ROUTES.docs,
    element: <GeneralLayout />,
    children: [
        {
            path: ROUTES.superdeporte + ROUTES.root + ROUTES.terminos,
            element: <TermsAndPrivacy marca={ROUTES.superdeporte} tipo={ROUTES.terminos} />,
        },
        {
            path: ROUTES.equinox + ROUTES.root + ROUTES.terminos,
            element: <TermsAndPrivacy marca={ROUTES.equinox} tipo={ROUTES.terminos} />,
        },
        {
            path: ROUTES.reebok + ROUTES.root + ROUTES.terminos,
            element: <TermsAndPrivacy marca={ROUTES.reebok} tipo={ROUTES.terminos} />,
        },
        {
            path: ROUTES.adidas + ROUTES.root + ROUTES.terminos,
            element: <TermsAndPrivacy marca={ROUTES.adidas} tipo={ROUTES.terminos} />,
        },
        {
            path: ROUTES.superdeporte + ROUTES.root + ROUTES.privacidad,
            element: <TermsAndPrivacy marca={ROUTES.superdeporte} tipo={ROUTES.privacidad} />,
        },
        {
            path: ROUTES.equinox + ROUTES.root + ROUTES.privacidad,
            element: <TermsAndPrivacy marca={ROUTES.equinox} tipo={ROUTES.privacidad} />,
        },
        {
            path: ROUTES.reebok + ROUTES.root + ROUTES.privacidad,
            element: <TermsAndPrivacy marca={ROUTES.reebok} tipo={ROUTES.privacidad} />,
        },
        {
            path: ROUTES.adidas + ROUTES.root + ROUTES.privacidad,
            element: <TermsAndPrivacy marca={ROUTES.adidas} tipo={ROUTES.privacidad} />,
        },
    ],
}
