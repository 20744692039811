import { Box, Typography } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { getEmpresaListSelector } from 'features'
import React from 'react'

interface Props {
    empresaId: number
}

export const FormHeader = (props: Props) => {
    const { empresaId } = props
    const empresas = useAppSelector(getEmpresaListSelector)

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 3,
                }}
            >
                {empresas
                    .filter((empresa) => empresa.id === empresaId)
                    .map((empresa) => (
                        <img key={empresa.marca} src={`/assets/${empresa.marca}.png`} alt={empresa.marca} width={empresa.ancho} />
                    ))}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    mt: 4,
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: '700', fontSize: '25px' }}>
                    AUTORIZA EL USO DE TUS DATOS PERSONALES
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    mt: 2,
                    mx: 2,
                }}
            >
                <Typography variant="body1" sx={{ fontWeight: '200', fontSize: '15px' }}>
                    A fin de cumplir con la Ley Orgánica de Protección de Datos Personales y para mantenerte informado de los diferentes beneficios, necesitamos
                    contar con tu consentimiento para el tratamiento de tus datos y la aceptación de nuestro Aviso de Privacidad.
                </Typography>
            </Box>
        </React.Fragment>
    )
}
