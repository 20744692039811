export enum DIALOGS_USER {
    NEW_USUARIO = 'new_usuario',
    EDIT_USUARIO = 'edit_usuario',
    EDIT_PASSWORD = 'edit_password',
    EDIT_ESTADO = 'edit_estado',
}

export enum DIALOGS_CLIENT {
    EDIT_CLIENTE = 'edit_cliente',
    AUDIT_CLIENTE = 'audit_cliente',
    SEND_EMAIL = 'send_email',
}
