import { Accordion, AccordionSummary, Typography, AccordionDetails, Icon, Box } from '@mui/material'
import { structure } from 'pages'
import React, { Fragment } from 'react'
import { ROUTES } from 'routes'

type Props = {
    listInfo: structure[]
    company: string
}

type StructureTerms = {
    key: number
    title: string
}

const ListTermsConditionalSuperdeporte: StructureTerms[] = [
    {
        key: 1,
        title: 'Factura Ganadora 2023',
    },
    {
        key: 2,
        title: '30 pares de zapatos',
    },
    {
        key: 3,
        title: 'Sorteo PUMA 3 órdenes de compra',
    },
    {
        key: 4,
        title: 'Sale Puma Abril',
    },
]

const ListTermsConditionalEquinox: StructureTerms[] = [
    {
        key: 1,
        title: 'Gana 10 órdenes de compra',
    },
    {
        key: 2,
        title: 'Nike Sorteo Tus Ídolos te Premian',
    },
]

const ListTermsConditionalReebok: StructureTerms[] = [
    {
        key: 1,
        title: '3 órdenes de compra de $100 cada una',
    },

]

const ListTermsConditionalAdidas: StructureTerms[] = [
    {
        key: 1,
        title: '6 pares de zapatos adidas, 2 outfits completos Hombre y 2 outfits completos Mujer adidas',
    },

]

const getTermsAndContionsByCompany = (company: string) => {
    if (company === ROUTES.superdeporte) {
        return ListTermsConditionalSuperdeporte
    } else if (company === ROUTES.equinox) {
        return ListTermsConditionalEquinox
    } else if (company === ROUTES.reebok) {
        return ListTermsConditionalReebok
    } else if (company === ROUTES.adidas) {
        return ListTermsConditionalAdidas
    }
}
export const AcordionAllTerms = (props: Props) => {
    const { listInfo, company } = props
    const termsAndCondiontions = getTermsAndContionsByCompany(company)
    return (
        <Fragment>

            {
                termsAndCondiontions ? (
                    termsAndCondiontions.map((info, index) => (
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<Icon>expand_more</Icon>}
                                aria-controls={`panel${index + 1}-content`}
                                id={`panel${index + 1}-header`}
                                style={{ marginBottom: '0' }}
                            >
                                <Typography sx={{ fontWeight: 700 }} dangerouslySetInnerHTML={{ __html: info.title }} />
                            </AccordionSummary>
                            <AccordionDetails style={{ textAlign: 'justify', paddingTop: '0px' }}>
                                {listInfo[index].map((data, dataIndex) => (
                                    <Box key={dataIndex}>
                                        <Typography dangerouslySetInnerHTML={{ __html: data.title }} />
                                        {data.paragraphs.map((paragraph, paIndex) => (
                                            <p key={paIndex} dangerouslySetInnerHTML={{ __html: paragraph }} style={{ marginTop: '0px', overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }} />
                                        ))}
                                    </Box>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <Typography sx={{ fontWeight: 700 }}>No se encontró información de términos y condiciones</Typography>

                )
            }
        </Fragment>
    )
}

export default AcordionAllTerms
