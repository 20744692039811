import axios, { AxiosError } from 'axios'
import { PATHS } from 'const'
import { ICliente } from 'types'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SECURITY_API_KEY}`,
    },
})

export const insertClienteService = async (cliente: ICliente) => {
    try {
        const response = (await instance.post(PATHS.CLIENTE_PATH, cliente)).data
        return response
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}
