import { Box, CssBaseline } from '@mui/material'
import { AppProvider } from 'app/context'
import { NavDrawer } from 'pages'
import { Outlet } from 'react-router-dom'

export const PrivateLayout = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppProvider>
                <NavDrawer>
                    <Outlet />
                </NavDrawer>
            </AppProvider>
        </Box>
    )
}
