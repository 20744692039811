import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { catalogoReducer } from 'features'
import { usuarioReducer } from 'features/usuario/Usuario.slice'

export const store = configureStore({
    reducer: {
        catalogo: catalogoReducer,
        usuario: usuarioReducer,
    },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
