import { Accordion, AccordionSummary, Typography, AccordionDetails, Icon } from '@mui/material'
import { structure } from 'pages'
import React, { Fragment } from 'react'

type Props = {
    listInfo: structure[]
}

export const AcordionListTerms = (props: Props) => {
    const { listInfo } = props
    return (
        <Fragment>
            {listInfo[0].map((info, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                        style={{ marginBottom: '0' }}
                    >
                        <Typography dangerouslySetInnerHTML={{ __html: info.title }} />
                    </AccordionSummary>
                    <AccordionDetails style={{ textAlign: 'justify', paddingTop: '0px' }}>
                        {info.paragraphs.map((paragraph, pIndex) => (
                            <p key={pIndex} dangerouslySetInnerHTML={{ __html: paragraph }} style={{ marginTop: '0px', overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }} />
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Fragment>
    )
}

export default AcordionListTerms
