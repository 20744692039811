import {
    Alert,
    AlertColor,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Icon,
    IconButton,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useAppSelector } from 'app/hooks'
import dayjs from 'dayjs'
import { getConceptoListSelector, getEmpresaListSelector, getGeneroListSelector, getTipoDocumentoListSelector } from 'features'
import React from 'react'

import { insertClienteService } from 'services'
import { getInfoClienteFromIntegrationService } from 'services/integration/integration.service'
import { IAlert, ICliente } from 'types'

interface Props {
    empresaId: number
}

const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const FormContent = (props: Props) => {
    const { empresaId } = props
    const tipoDocumentoList = useAppSelector(getTipoDocumentoListSelector)
    const generoList = useAppSelector(getGeneroListSelector)
    const conceptoList = useAppSelector(getConceptoListSelector)
    const empresaList = useAppSelector(getEmpresaListSelector)
    const [data, setData] = React.useState<ICliente>({} as ICliente)
    const [alerts, setAlerts] = React.useState<IAlert[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [loading2, setLoading2] = React.useState<boolean>(false)
    const [certificado, setCertificado] = React.useState<string>()

    const changeValueEntity = (key: keyof ICliente, value: ICliente[keyof ICliente]) => {
        setData((prev) => ({ ...prev, [key]: value }))
    }

    const getValueData = (key: keyof ICliente): ICliente[keyof ICliente] => {
        const value = data[key]
        return value ?? ''
    }

    const getPrivacidadLink = (empresaId: number) => {
        if (empresaId === 1) {
            return 'https://avisoprivacidad.superdeporte.com.ec'
        }
        if (empresaId === 2 || empresaId === 3) {
            return 'https://avisoprivacidad.mundodeportivo-ec.com'
        }
        if (empresaId === 4) {
            return 'https://avisoprivacidad.equinox-ec.com'
        }
    }

    const getTerminosLink = (empresaId: number) => {
        if (empresaId === 1) {
            return 'https://terminos.superdeporte.com.ec'
        }
        if (empresaId === 2) {
            return 'https://terminos-adidas.mundodeportivo-ec.com'
        }
        if (empresaId === 3) {
            return 'https://terminos.mundodeportivo-ec.com'
        }
        if (empresaId === 4) {
            return 'https://terminos.equinox-ec.com'
        }
    }

    const handleSearchClientInfo = async () => {
        if (data.numDocumento !== undefined) {
            setLoading(true)
            const empresa = empresaList.filter((emp) => emp.id === empresaId)
            if (empresa !== undefined) {
                try {
                    const clienteIntegration = await getInfoClienteFromIntegrationService(empresa[0].identificacion, data.numDocumento)
                    const clienteArray = clienteIntegration.nombres.split(' ')
                    const filteredArray = clienteArray.filter((nombre) => nombre !== '')
                    if (filteredArray.length === 1) {
                        changeValueEntity('nombres', filteredArray[0])
                    } else if (filteredArray.length === 2) {
                        changeValueEntity('nombres', filteredArray[0])
                        changeValueEntity('apellidos', filteredArray[1])
                    } else if (filteredArray.length === 3) {
                        changeValueEntity('nombres', filteredArray[0])
                        changeValueEntity('apellidos', filteredArray.filter((element, index) => index !== 0).join(' '))
                    } else if (filteredArray.length > 3) {
                        changeValueEntity('nombres', filteredArray.filter((element, index) => index < 2).join(' '))
                        changeValueEntity('apellidos', filteredArray.filter((element, index) => index > 1).join(' '))
                    }

                    if (clienteIntegration.email !== null) {
                        changeValueEntity('email', clienteIntegration.email)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            setLoading(false)
        } else {
            console.log('No hay numDocumento')
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoading2(true)
        const actualAlerts: IAlert[] = []
        let esMayor = true

        if (data.nombres === undefined || data.nombres.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar sus nombres.' })
        }

        if (data.apellidos === undefined || data.apellidos.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar sus apellidos.' })
        }

        if (data.tipoDocumento === undefined) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe seleccionar un tipo de documento.' })
        }

        if (data.numDocumento === undefined || data.numDocumento.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un número de documento.' })
        }

        if (
            data.tipoDocumento !== undefined &&
            (data.tipoDocumento === '04' || data.tipoDocumento === '05') &&
            data.numDocumento !== undefined &&
            data.numDocumento.trim().length > 0
        ) {
            if (data.tipoDocumento === '04' && (data.numDocumento.match(/^[0-9]+$/) === null || data.numDocumento.trim().length !== 10)) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar una cédula válida.' })
            }
            if (
                data.tipoDocumento === '05' &&
                (data.numDocumento.match(/^[0-9]+$/) === null || data.numDocumento.trim().length !== 13 || !data.numDocumento.endsWith('001'))
            ) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un RUC válido.' })
            }
        }

        if (data.genero === undefined) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe seleccionar un género.' })
        }

        if (data.email === undefined || data.email.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un email.' })
        }

        if (data.email !== undefined && data.email.trim().length > 0) {
            if (!expression.test(data.email)) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un email válido.' })
            }
        }

        if (data.fechaNacimiento === undefined) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar su fecha de nacimiento.' })
        }

        if (data.fechaNacimiento !== undefined && data.fechaNacimiento.trim().length > 0) {
            if (data.fechaNacimiento.toString() === 'Invalid Date') {
            } else {
                try {
                    const nacimientoDayjs = dayjs(data.fechaNacimiento)
                    const age = dayjs().diff(nacimientoDayjs, 'year')
                    const correo = empresaList.find((empresa) => empresa.id === empresaId)!.correo
                    if (age < 18) {
                        esMayor = false
                        actualAlerts.push({ severity: 'error', title: 'Error', message: `Si eres menor de edad comunícate con el mail ${correo}` })
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }

        if (data.celular === undefined || data.celular.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un número celular.' })
        }

        if (data.celular !== undefined && data.celular.trim().length > 0) {
            if (data.celular.match(/^[0-9]+$/) === null || data.celular.trim().length !== 10) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'Ingrese un número de celular válido.' })
            }
        }

        if (data.avisoPrivacidad === undefined || !data.avisoPrivacidad) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe aceptar el aviso de privacidad.' })
        }

        if ( data.terminos === undefined || !data.terminos) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe aceptar los términos y condiciones.' })
        }

        if (actualAlerts.length > 0) {
            setAlerts(actualAlerts)
        } else {
            const empresa = empresaList.find((empresa) => empresa.id === empresaId)
            const conceptosString =
                empresaId === 1
                    ? conceptoList
                          .filter((concepto) => concepto.empresaId === 1)
                          .map((concepto) => concepto.conceptoId)
                          .join(',')
                    : empresa!.concepto
            const cliente = { ...data, esMayorEdad: esMayor, empresa: empresaId, conceptos: conceptosString }
            const response = await insertClienteService(cliente)
            if (response === 0) {
                actualAlerts.push({
                    severity: 'success',
                    title: '',
                    message: `Muchas gracias por registrarte, ya te encuentras en nuestra base de datos.  Si necesitas algún cambio en tu información, aplicar a tus derechos de suspender tu suscripción para no recibir información de alguno de nuestros canales comerciales, por favor comunícate con el mail ${
                        empresa!.correo
                    }`,
                })
                setAlerts(actualAlerts)
            } else {
                actualAlerts.push({
                    severity: 'success',
                    title: 'Tu registro se encuentra completo.',
                    message:
                        'Valoramos mucho la confianza depositada en nosotros con la aceptación de nuestro Aviso de Privacidad. Hemos reforzado la protección de tus datos y los utilizaremos para los fines que nos has autorizado.',
                })
                setAlerts(actualAlerts)
                setCertificado(response)
            }
        }
        setLoading2(false)
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel style={{ color: '#C4C4C4' }}>Tipo de documento</InputLabel>
                        <Select
                            id="tipoDocumento"
                            name="tipoDocumento"
                            value={getValueData('tipoDocumento').toString()}
                            onChange={(event: SelectChangeEvent) => {
                                changeValueEntity('tipoDocumento', event.target.value)
                            }}
                            label="Tipo de documento"
                            style={{ borderRadius: '0px' }}
                        >
                            {tipoDocumentoList.map((tipoDocumento) => (
                                <MenuItem key={tipoDocumento.codigo} value={tipoDocumento.codigo}>
                                    {tipoDocumento.descripcion}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="numDocumento"
                        name="numDocumento"
                        label="Número de documento"
                        variant="outlined"
                        value={getValueData('numDocumento')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            changeValueEntity(event.target.name as keyof ICliente, event.target.value)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" sx={{ mr: '2px' }}>
                                    <IconButton edge="end" onClick={handleSearchClientInfo}>
                                        <Icon sx={{ fontSize: 25 }}>search</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                {loading && (
                    <Box
                        sx={{
                            mx: 4,
                        }}
                    >
                        <LinearProgress />
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="nombres"
                        name="nombres"
                        label="Nombres"
                        variant="outlined"
                        value={getValueData('nombres')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            changeValueEntity(event.target.name as keyof ICliente, event.target.value.toUpperCase())
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="apellidos"
                        name="apellidos"
                        label="Apellidos"
                        variant="outlined"
                        value={getValueData('apellidos')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            changeValueEntity(event.target.name as keyof ICliente, event.target.value.toUpperCase())
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <FormControl sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                        <RadioGroup
                            row
                            sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}
                            value={getValueData('genero').toString()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                changeValueEntity('genero', event.target.value)
                            }}
                        >
                            {generoList.map((genero) => (
                                <FormControlLabel
                                    key={genero.codigo}
                                    value={genero.codigo}
                                    control={<Radio />}
                                    label={<Typography sx={{ fontWeight: '300' }}>{genero.descripcion}</Typography>}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        variant="outlined"
                        value={getValueData('email')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            changeValueEntity(event.target.name as keyof ICliente, event.target.value)
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <DatePicker
                        label="Fecha de nacimiento"
                        format="YYYY-MM-DD"
                        value={getValueData('fechaNacimiento') !== '' ? dayjs(getValueData('fechaNacimiento').toString()) : null}
                        onChange={(newValue) => {
                            if (newValue !== null) {
                                changeValueEntity('fechaNacimiento', newValue.format('YYYY-MM-DD'))
                            }
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="celular"
                        name="celular"
                        label="Celular"
                        variant="outlined"
                        value={getValueData('celular')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            changeValueEntity(event.target.name as keyof ICliente, event.target.value)
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!getValueData('avisoPrivacidad')}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        changeValueEntity('avisoPrivacidad', event.target.checked)
                                    }}
                                />
                            }
                            label={
                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                    He leído y acepto el{' '}
                                    <a href={getPrivacidadLink(empresaId)} rel="noreferrer" target="_blank">
                                        Aviso de Privacidad
                                    </a>{' '}
                                    para el tratamiento de mis datos personales.
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!getValueData('terminos')}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        changeValueEntity('terminos', event.target.checked)
                                    }}
                                />
                            }
                            label={
                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                    He leído y acepto los{' '}
                                    <a href={getTerminosLink(empresaId)} rel="noreferrer" target="_blank">
                                        Términos y Condiciones del Sorteo
                                    </a>
                                    .
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        my: 2,
                        mx: 4,
                    }}
                >
                    {alerts.map((alert, index) => (
                        <Box
                            key={'alert' + index.toString()}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                m: '4px',
                            }}
                        >
                            <Alert severity={alert.severity as AlertColor}>
                                <AlertTitle>{alert.title}</AlertTitle>
                                {alert.message}
                            </Alert>
                        </Box>
                    ))}
                </Box>
                {certificado && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            my: 2,
                            mx: 4,
                        }}
                    >
                        <img alt="qr" src={`https://newsletter.superdeporte.com.ec/api/cliente/qr?code=${certificado}`} width={150} />
                        <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>{certificado}</Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        mx: 4,
                    }}
                >
                    <Button variant="outlined" type="submit" disabled={loading2}>
                        REGISTRARSE
                    </Button>
                </Box>
                {loading2 && (
                    <Box
                        sx={{
                            mx: 4,
                        }}
                    >
                        <LinearProgress />
                    </Box>
                )}
            </form>
        </React.Fragment>
    )
}
