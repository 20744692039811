import axios, { AxiosError } from 'axios'
import { LOCALSTORAGE_KEYS, PATHS } from 'const'
import { AuditoriaResponse, ClienteRequest, ClienteResponse, PaginationResponse } from 'types'

type IHeaders = {
    Authorization: string
    Token: string
}
const getItem = (key: string): any | undefined => {
    const value = localStorage.getItem(key)
    try {
        if (value) {
            const json = JSON.parse(value)
            return json
        }
    } catch (error) {
        return undefined
    }
}
const getHeaders = (): IHeaders => {
    const token = getItem(LOCALSTORAGE_KEYS.MARATHON) ?? ''
    return {
        Authorization: `Bearer ${process.env.REACT_APP_SECURITY_API_KEY}`,
        Token: token,
    }
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
})

const setAuthHeaders = (Authorization: string, Token: string) => {
    instance.defaults.headers.common['Authorization'] = Authorization
    instance.defaults.headers.common['Token'] = Token
}

export const getClienteListService = async (limit: number, page: number, empresa?: number, concepto?: string, busqueda?: string) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = await instance.get(PATHS.CLIENTE_PATH, { params: { limit, page, empresaId: empresa, concepto, busqueda } })
        return response.data as PaginationResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const updateClienteService = async (cliente: ClienteRequest) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = (await instance.put(PATHS.CLIENTE_PATH, cliente)).data
        return response as ClienteResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const getAuditoriaByClienteListService = async (clienteId: number) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = await instance.get(PATHS.AUDITORIA_PATH, { params: { clienteId } })
        return response.data as AuditoriaResponse[]
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const getClienteReporteService = async (empresa?: number, concepto?: string, busqueda?: string) => {
    try {
        const response = await instance.get(PATHS.CLIENTE_PATH + '/reporte', { params: { empresaId: empresa, concepto, busqueda }, responseType: 'blob' })
        return response.data
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const sendEmailClienteService = async (certificado: string,empresa:number, emails: string[]) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = await instance.post(PATHS.CLIENTE_PATH + '/email', { certificado, empresa, emails })
        return response.data
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}
