import { useAppDispatch } from 'app/hooks'
import {
    getConceptoListService,
    getEmpresaListService,
    getGeneroListService,
    getTipoClienteListService,
    getTipoDocumentoListService,
    setConceptoList,
    setEmpresaList,
    setGeneroList,
    setTipoClienteList,
    setTipoDocumentoList,
} from 'features'
import React from 'react'

export const AppContext = React.createContext({})

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useAppDispatch()

    const getCatalogoList = React.useCallback(async () => {
        try {
            const tipoDocumentoList = await getTipoDocumentoListService()
            dispatch(setTipoDocumentoList(tipoDocumentoList))

            const generoList = await getGeneroListService()
            dispatch(setGeneroList(generoList))

            const empresaList = await getEmpresaListService()
            dispatch(setEmpresaList(empresaList))

            const conceptoList = await getConceptoListService()
            dispatch(setConceptoList(conceptoList))

            const tipoClienteList = await getTipoClienteListService()
            dispatch(setTipoClienteList(tipoClienteList))
        } catch (error) {
            console.log(error)
        }
    }, [dispatch])

    React.useEffect(() => {
        getCatalogoList()
    }, [])

    return <AppContext.Provider value={{}}>{children}</AppContext.Provider>
}
