

export const fieldValidationIdentification = (identificacion: string) => {
  const regexIdentification: RegExp = /^\d{10}$/;
  return regexIdentification.test(identificacion);
};

export const fieldValidationEmail = (email: string) => {
  const regexEmail: RegExp  = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regexEmail.test(email);
};

export const fieldValidatinPassword = (password: string) => {
  const regexPassword: RegExp  = /.{8,}/;
    return regexPassword.test(password);
};