import axios, { AxiosError } from 'axios'
import { PATHS } from 'const'
import { IConcepto, IEmpresa, IGenero, ITipoCliente, ITipoDocumento } from 'types'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SECURITY_API_KEY}`,
    },
})

export const getTipoDocumentoListService = async () => {
    try {
        const response = await instance.get(PATHS.CATALOGO_PATH + PATHS.TIPO_DOCUMENTO_PATH)
        return response.data as ITipoDocumento[]
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const getGeneroListService = async () => {
    try {
        const response = await instance.get(PATHS.CATALOGO_PATH + PATHS.GENERO_PATH)
        return response.data as IGenero[]
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const getEmpresaListService = async () => {
    try {
        const response = await instance.get(PATHS.CATALOGO_PATH + PATHS.EMPRESA_PATH)
        return response.data as IEmpresa[]
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const getConceptoListService = async () => {
    try {
        const response = await instance.get(PATHS.CATALOGO_PATH + PATHS.CONCEPTO_PATH)
        return response.data as IConcepto[]
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const getTipoClienteListService = async () => {
    try {
        const response = await instance.get(PATHS.CATALOGO_PATH + PATHS.TIPO_CLIENTE_PATH)
        return response.data as ITipoCliente[]
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}
