import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    useTheme,
} from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { getGeneroListSelector, getTipoDocumentoListSelector } from 'features'
import React, { Fragment } from 'react'
import { ClienteResponse, PaginationResponse } from 'types'
import EditIcon from '@mui/icons-material/Edit'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck'
import EmailIcon from '@mui/icons-material/Email'
import { DIALOGS_CLIENT } from 'const'

interface TablePaginationActionsProps {
    count: number
    page: number
    rowsPerPage: number
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0)
    }

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1)
    }

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    )
}

interface Props {
    busqueda: string | undefined
    concepto: string | undefined
    empresa: number | undefined
    clientes: PaginationResponse | undefined
    page: number
    rowsPerPage: number
    handleSetPage: (page: number) => void
    handleSetRowsPerPage: (rowsPerPage: number) => void
    handleGetClientes: (rowsPerPage: number, page: number, empresa?: number, concepto?: string, busqueda?: string) => Promise<void>
    handleOpenDialog: (cliente: ClienteResponse, dialog: string) => void
}

export const ClientesTable = (props: Props) => {
    const { busqueda, concepto, empresa, clientes, page, rowsPerPage, handleSetPage, handleSetRowsPerPage, handleGetClientes, handleOpenDialog } = props
    const tipoDocumentos = useAppSelector(getTipoDocumentoListSelector)
    const generos = useAppSelector(getGeneroListSelector)

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clientes!.count) : 0

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        handleSetPage(newPage)
        handleGetClientes(rowsPerPage, newPage, empresa,concepto, busqueda)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleSetRowsPerPage(parseInt(event.target.value, 10))
        handleSetPage(0)
        handleGetClientes(parseInt(event.target.value, 10), 0, empresa,concepto, busqueda)
    }

    React.useEffect(() => {
        handleGetClientes(rowsPerPage, page, empresa, concepto, busqueda)
    }, [])

    return (
        <TableContainer component={Paper} sx={{maxWidth:'100%', mr:{xs:5,sm:0} }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Nombres</TableCell>
                        <TableCell>Apellidos</TableCell>
                        <TableCell>Tipo Doc</TableCell>
                        <TableCell>Num. Doc</TableCell>
                        <TableCell>Género</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Fecha Nac.</TableCell>
                        <TableCell>Celular</TableCell>
                        <TableCell>Insertado</TableCell>
                        <TableCell>Activo</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                {clientes && (
                    <Fragment>
                         <TableBody>
                            {clientes.result.map((cliente) => (
                                <TableRow key={cliente.clienteId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {cliente.nombres}
                                    </TableCell>
                                    <TableCell component="th" scope="row" >
                                        {cliente.apellidos}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '4px', textAlign:'center'}}>
                                        {tipoDocumentos.find((t) => t.codigo === cliente.tipoDocumento)?.descripcion}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '2px',textAlign:'center' }} >
                                        {cliente.numDocumento}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '4px', textAlign:'center' }}>
                                        {generos.find((g) => g.codigo === cliente.genero)?.descripcion}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '3px'}}>
                                        {cliente.email}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{paddingX: '3px', textAlign:'center'}}>
                                        {cliente.fechaNacimiento}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '5px' }}>
                                        {cliente.celular}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '5px' }}>
                                        {cliente.insertado}
                                    </TableCell>
                                    <TableCell component="th" scope="row"  sx={{paddingX: '4px', textAlign:'center'}}>
                                        {cliente.activo ? 'Activo' : 'Inactivo'}
                                    </TableCell>
                                    <TableCell component="th" scope="row" >
                                        <Tooltip title="Editar">
                                            <IconButton onClick={() => handleOpenDialog(cliente, DIALOGS_CLIENT.EDIT_CLIENTE)} size="small">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Ver Auditoría">
                                            <IconButton onClick={() => handleOpenDialog(cliente, DIALOGS_CLIENT.AUDIT_CLIENTE)} size="small">
                                                <SafetyCheckIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            Boolean(cliente.certificado && cliente.certificado!==undefined) &&
                                        <Tooltip title="Enviar Correo QR">
                                            <IconButton onClick={() => handleOpenDialog(cliente, DIALOGS_CLIENT.SEND_EMAIL)} size="small">
                                                <EmailIcon />
                                            </IconButton>
                                        </Tooltip>
                                        } 
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody> 
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    colSpan={11}
                                    count={clientes.count}
                                    labelRowsPerPage="registros por página"
                                    labelDisplayedRows={(page) => `${page.from} - ${page.to === -1 ? page.count : page.to} de ${page.count}`}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter> 
                    </Fragment>
                )}
            </Table>
        </TableContainer>
    )
}
