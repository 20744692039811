import WarningIcon from '@mui/icons-material/Warning'
import { Alert, AlertColor, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Typography } from '@mui/material'
import { DIALOGS_USER } from 'const'
import React, { Fragment } from 'react'
import { updateUsuarioActivoService } from 'services'
import { IAlert, UsuarioResponse } from 'types'

interface Props {
    open: boolean
    usuario: UsuarioResponse | undefined
    handleClose: (dialog: string) => void
    handleSetSelectedUsuario: (usuario: UsuarioResponse) => void
}

export const EditUsuarioStateDialog = (props: Props) => {
    const { open, usuario, handleClose, handleSetSelectedUsuario } = props
    const [alert, setAlert] = React.useState<IAlert>()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setAlert(undefined)
        if (usuario) {
            const response = await updateUsuarioActivoService(usuario.usuarioId)
            handleSetSelectedUsuario(response)
        }
        setAlert({ severity: 'success', title: '', message: 'El estado del Usuario ha sido modificado.' })
        handleClose(DIALOGS_USER.EDIT_ESTADO)
    }

    const handleCloseSnackbar = () => {
        setAlert(undefined)
    }

    return (
        <Fragment>
            {usuario && (
                <Dialog
                    open={open}
                    onClose={() => {
                        handleClose(DIALOGS_USER.EDIT_ESTADO)
                    }}
                    fullWidth
                    maxWidth="xs"
                >
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>Edición del estado del Usuario</DialogTitle>
                        <DialogContent>
                            <Alert severity="warning" style={{ fontSize: '28px' }} icon={<WarningIcon />}>
                                <AlertTitle>Advertencia</AlertTitle>
                                <Typography>¿Estás seguro de cambiar el estado del usuario?</Typography>
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" size="small" sx={{ height: '40px', fontSize: '14px' }}>
                                SÍ, MODIFICAR
                            </Button>
                            <Button
                                size="small"
                                onClick={() => {
                                    handleClose(DIALOGS_USER.EDIT_ESTADO)
                                }}
                                sx={{ height: '40px', fontSize: '14px' }}
                            >
                                NO
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
            <Snackbar open={alert !== undefined} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={alert?.severity as AlertColor} onClose={handleCloseSnackbar}>
                    {alert?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}
