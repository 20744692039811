export const ROUTES = {
    root: '/',
    none: '',
    login: 'login',
    private: 'dashboard',
    docs: 'docs',
    superdeporte: 'superdeporte',
    nike: 'nike',
    adidas: 'adidas',
    reebok: 'reebok',
    equinox: 'equinox',
    terminos: 'terminos',
    privacidad: 'privacidad',
    usuarios: 'usuarios',
    clientes: 'clientes',
    other: '*',
}
