import { createTheme, ThemeOptions } from '@mui/material/styles'
import { COLORS } from 'styles'

declare module '@mui/material/styles' {
    interface Palette {
        alt?: { background: string }
    }
    interface PaletteOptions {
        alt?: { background: string }
    }
}

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: COLORS.primary.main,
        },
        secondary: {
            main: COLORS.secondary.main,
        },
    },
    typography: {
        fontFamily: 'Oswald',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: '25px',
                    color: COLORS.buttonText.main,
                    backgroundColor: COLORS.background.main,
                    padding: '8px 8px',
                    borderRadius: '0px',
                    '&:hover': {
                        backgroundColor: COLORS.background.main,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        transform: 'scale(1.1)',
                    },
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        transform: 'scale(1.1)',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                    input: {
                        color: COLORS.primary.main,
                    },
                    '& label': { color: COLORS.secondary.main },
                    '& fieldset': {
                        borderRadius: '0px',
                        color: COLORS.primary.main,
                    },
                    '& label.Mui-focused': {
                        color: COLORS.primary.main,
                    },
                },
            },
        },
    },
}

export const theme = createTheme(themeOptions)
