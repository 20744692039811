import {
    Alert,
    AlertColor,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import { DIALOGS_USER } from 'const'
import React, { Fragment } from 'react'
import { updateUsuarioService } from 'services'
import { EditUsuarioRequest, IAlert, UsuarioResponse } from 'types'
import { fieldValidationEmail, fieldValidationIdentification } from 'utils'

interface Props {
    open: boolean
    usuario: UsuarioResponse | undefined
    handleClose: (dialog: string) => void
    handleSetSelectedUsuario: (usuario: UsuarioResponse) => void
}

export const EditUsuarioDialog = (props: Props) => {
    const { open, usuario, handleClose, handleSetSelectedUsuario } = props
    const [data, setData] = React.useState<UsuarioResponse>({} as UsuarioResponse)
    const [alert, setAlert] = React.useState<IAlert>()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const actualAlerts: IAlert[] = []

        if (data.nombre === undefined || data.nombre.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar los nombres.' })
        }

        if (data.identificacion === undefined || data.identificacion.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar la identificación' })
        }

        if (data.identificacion !== undefined && data.identificacion.trim().length > 0) {
            if (!fieldValidationIdentification(data.identificacion)) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'La identificacion debe ser válida y tener 10 dígitos.' })
            }
        }

        if (data.correo === undefined || data.correo.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un email.' })
        }

        if (data.correo !== undefined && data.correo.trim().length > 0) {
            if (!fieldValidationEmail(data.correo)) {
                actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar un email válido.' })
            }
        }

        if (actualAlerts.length > 0) {
            setAlert(actualAlerts[0])
        } else {
            setAlert(undefined)
            const usuarioRequest: EditUsuarioRequest = {
                usuarioId: usuario?.usuarioId as number,
                nombre: data.nombre,
                identificacion: data.identificacion,
                correo: data.correo,
                permisoUsuarios: data.permisoUsuarios,
                permisoSuperdeporte: data.permisoSuperdeporte,
                permisoMedeportAdidas: data.permisoMedeportAdidas,
                permisoMedeportReebok: data.permisoMedeportReebok,
                permisoEquinox: data.permisoEquinox,
            }
            const response = await updateUsuarioService(usuarioRequest)
            handleSetSelectedUsuario(response)
            setAlert({ severity: 'success', title: '', message: 'El registro del Usuario ha sido modificado.' })
            handleClose( DIALOGS_USER.EDIT_USUARIO)
        }
    }

    const handleCloseSnackbar = () => {
        setAlert(undefined)
    }

    const changeValueEntity = (key: keyof UsuarioResponse, value: UsuarioResponse[keyof UsuarioResponse]) => {
        setData((prev) => ({ ...prev, [key]: value }))
    }

    const getValueData = (key: keyof UsuarioResponse): UsuarioResponse[keyof UsuarioResponse] => {
        const value = data[key]
        return value ?? ''
    }

    React.useEffect(() => {
        if (usuario !== undefined) setData(usuario)
    }, [usuario])

    return (
        <Fragment>
            {usuario && (
                <Dialog
                    open={open}
                    onClose={() => {
                        handleClose( DIALOGS_USER.EDIT_USUARIO)
                    }}
                    fullWidth
                    maxWidth="md"
                >
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>Editar Información del Usuario</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Toda edición de información registra la fecha y hora de actualización.</DialogContentText>
                            <Grid container gap={2} sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item xs={3.6}>
                                    <TextField
                                        id="nombre"
                                        name="nombre"
                                        label="Nombre"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('nombre')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity(event.target.name as keyof UsuarioResponse, event.target.value.toUpperCase())
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3.6}>
                                    <TextField
                                        id="identificacion"
                                        name="identificacion"
                                        label="Identificación"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('identificacion')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity(event.target.name as keyof UsuarioResponse, event.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3.6}>
                                    <TextField
                                        id="correo"
                                        name="correo"
                                        label="Correo"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('correo')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity(event.target.name as keyof UsuarioResponse, event.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('permisoUsuarios')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('permisoUsuarios', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                    Permiso para Usuarios
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('permisoSuperdeporte')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('permisoSuperdeporte', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                    Permiso para Superdeporte
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('permisoMedeportAdidas')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('permisoMedeportAdidas', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                    Permiso para Medeport Adidas
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('permisoMedeportReebok')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('permisoMedeportReebok', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                    Permiso para Medeport Rebook
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('permisoEquinox')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('permisoEquinox', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                    Permiso para Equinox
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={5.6}>
                                    <TextField
                                        id="insertado"
                                        name="insertado"
                                        label="Insertado"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={getValueData('inserted')}
                                    />
                                </Grid>
                                <Grid item xs={5.6}>
                                    <TextField
                                        id="actualizado"
                                        name="actualizado"
                                        label="Actualizado"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={getValueData('updated')}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" size="small" sx={{ height: '40px', fontSize: '14px' }}>
                                MODIFICAR
                            </Button>
                            <Button
                                size="small"
                                onClick={() => {
                                    handleClose( DIALOGS_USER.EDIT_USUARIO)
                                }}
                                sx={{ height: '40px', fontSize: '14px' }}
                            >
                                CANCELAR
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
            <Snackbar open={alert !== undefined} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={alert?.severity as AlertColor} onClose={handleCloseSnackbar}>
                    {alert?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}
